/* --------------------------
フッター
-------------------------- */

.p-footer {
    width: 100%;
    max-width: 100%;
    background-image: url(../images/common/bg-image.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &-inner {
        width: 1280px;
        max-width: 100%;
        margin: 0 auto;
        padding: 9px 10px;

        @include view-at(mdpi) {
            width: 100%;
        }
    }

    &-copyright {
        color: $color-white;
        font-size: rem(12px);
        text-align: center;
        line-height: 1;
    }
}
