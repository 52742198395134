/* --------------------------
見出し
-------------------------- */
.c-h2 {
    line-height: 1.6;

    &-lifetime {
        @include font-garamond;
        font-size: rem(52px);
        letter-spacing: 0.3em;

        @include view-at(sp) {
            font-size: rem(48px);
        }
    }

    &-member {
        color: $color-brown;
        font-size: rem(32px);
        font-weight: $medium;
        letter-spacing: 0.05em;

        @include view-at(sp) {
            font-size: rem(28px);
        }
    }
}

.c-h3 {
    line-height: 1.6;

    &-mv {
        color: $color-brown-dark;
        font-size: rem(24px);
        font-weight: $medium;
        letter-spacing: 0.05em;
    }

    &-lifetime {
        color: #B5A635;
        font-size: rem(24px);
        font-weight: $medium;
        letter-spacing: 0.05em;
    }
}

.c-h4 {
    line-height: 1.6;
}

.c-h5 {
    line-height: 1.6;
}

.c-h6 {
    line-height: 1.6;
}

.c-h--center-line {
    @include center-line();
    margin-bottom: 5px;
}