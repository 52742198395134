
/* --------------------------
color
-------------------------- */

$color-text: #000;
$color-text-light: #595757;

$color-bright_yellow: #E9A014;
$color-brown: #B4641E;
$color-brown-dark: #892E06;

$color-red: #e94230;
$color-blue: #103674;
$color-blue-dark: #1B1B49;
$color-yellow: #FFF14B;
$color-yellow-light: #FCF6B9;
$color-yellow-snow: #FFFCD1;
$color-green: #008742;
$color-green-light: #A7CC5E;
$color-yellow_green: #CBD32E;
$color-gold: #DECC93;
$color-gold-dark: #A0815B;
$color-gold-light: #E2CE94;
$color-gold-snow: #EDEBDD;
$color-white: #fff;
$color-white-dark: rgba(255, 255, 255, .85);
$color-white-light: rgba(255, 255, 255, .5);
$color-white-snow: rgba(255, 255, 255, 0.15);
$color-black: #000;
$color-black-dark: rgba(0, 0, 0, .85);
$color-black-light: rgba(0, 0, 0, .5);
$color-black-snow: rgba(0, 0, 0, .15);

$color-gray: #ccc;
$color-gray-dark: #484B44;
$color-gray-light: #eee;
$color-gray-snow: #F5F5F5;

$color-bg: #F6F5EE;
$color-border: #DCDCDC;

/* --------------------------
spacing
-------------------------- */
$space: 100px, 70px, 50px, 35px;
$space-tab: 80px, 60px, 40px, 20px;
$space-sp: 60px, 40px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$black: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$thin: 100;
$normal: 300;

/* --------------------------
contents width  
-------------------------- */
$contents-width: 1320px;
$contents-middle-width: 1160px;
$contents-narrow-width: 1000px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
