/* --------------------------
ヘッダー
-------------------------- */

.p-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 74px;
    max-width: 100%;
    background-image: url(../images/common/bg-image.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1000;

    @include view-at(sp) {
        height: 54px;
    }

    &-inner {
        width: 1280px;
        max-width: 100%;
        margin: 0 auto;
        padding: 21px 10px;

        @include view-at(laptop) {
            width: 100%;
        }
        @include view-at(sp) {
            padding: 15px 10px;
        }
    }

    &-logo {
        text-align: center;

        a {
            display: inline-block;

            img {
                max-height: 32px;

                @include view-at(sp) {
                    max-height: 24px;
                }
            }
        }
    }
}

