/* --------------------------
ボタン
-------------------------- */
.c-button {
    display: inline-block;
    color: $color-white;
    font-size: rem(30px);
    font-weight: $bold;
    padding: 20px 40px;
    background-color: $color-green;

    @include view-at(mdpi) {
        font-size: rem(27px);
    }
    @include view-at(laptop) {
        font-size: rem(22px);
        padding: 15px 30px;
    }
    @include view-at(tab) {
        font-size: rem(18px);
    }
}

