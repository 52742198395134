@charset "UTF-8";

.top {
    &-mv {
        &-bg {
            background: linear-gradient(180deg, $color-gold-light 0%, $color-gold-light 25%, $color-gold-dark 100%);
        }

        &-wrapper {
            display: flex;
            justify-content: flex-end;

            @include view-at(sp) {
                display: block;
            }
        }
        &-image {
            width: 39.6%;

            @include view-at(sp) {
                width: 100%;
            }

            &-inner {
                margin-right: -160px;

                @include view-at(laptop) {
                    margin-right: calc(-50vw + 480px);
                }
                @include view-at(tab) {
                    margin-right: -20px;
                }
                @include view-at(sp) {
                    margin: 0 -15px;
                }
            }
            &-row {
                display: flex;
                flex-wrap: wrap;
            }
            &-col {
                &.left {
                    width: 50%;
                    padding: 4px 2px 4px 0;
                }
                &.right {
                    width: 50%;
                    padding: 4px 0 4px 2px;
                }
            }
        }
        &-detail {
            width: 60.4%;
            padding-right: 100px;

            @include view-at(tab) {
                padding-right: 70px;
            }
            @include view-at(sp) {
                width: 100%;
                padding-right: 0;
            }
        }
        &-copy {
            padding: 120px 0 70px;

            @include view-at(tab) {
                padding: 80px 0 50px;
            }
            @include view-at(sp) {
                padding: 40px 0 30px;
            }

            &-inner {
                position: relative;

                @include view-at(sp) {
                    width: 80%;
                    margin: auto;
                }

                img {
                    &:not(:first-child) {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        &-border {
            border-bottom: 1px solid $color-white-light;
        }
    }

    &-about {
        position: relative;
        padding: 80px 0;
        background-color: $color-gold;
        z-index: 1;

        @include view-at(sp) {
            padding: 40px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            width: 50vw;
            height: 100%;
            background-color: $color-gold;
            z-index: -1;
        }

        &-inner {
            display: flex;
            flex-direction: row-reverse;

            @include view-at(sp) {
                display: block;
                padding-right: 15px;
            }
        }
        &-left {
            width: 37.5%;
            padding-right: 60px;

            @include view-at(sp) {
                width: 100%;
                padding-right: 0;
            }
        }
        &-right {
            width: 62.5%;
            padding-right: 80px;

            @include view-at(sp) {
                width: 100%;
                padding-right: 0;
            }
        }

        &-point {
            padding: 26px 30px 20px;
            border: 1px solid $color-white;

            &-title {
                position: relative;
                width: 300px;
                @include font-yugothic;
                color: $color-text-light;
                font-size: rem(16px);
                font-weight: $bold;
                text-align: center;
                padding: 7px;
                margin: 0 auto -16px;
                background-color: $color-gold;
                border: 1px solid $color-white;

                @include view-at(sp) {
                    width: 60%;
                }
            }

            &-list {
                display: flex;
                flex-wrap: wrap;
                margin: -5px;

                li {
                    width: calc(100% / 4);
                    padding: 5px;

                    @include view-at(sp) {
                        width: 50%;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        @include font-yugothic;
                        color: $color-text-light;
                        font-size: rem(14px);
                        font-weight: $bold;
                        text-align: center;
                        padding: 12px 4px;
                        background-color: #F7EDCB;
                        border-radius: 2px;
                    }
                }
            }
        }
    }

    &-border {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid $color-border;

        &-white {
            padding-bottom: 100px;
            margin-bottom: 100px;
            border-bottom: 1px solid $color-white;

            @include view-at(sp) {
                padding-bottom: 60px;
                margin-bottom: 60px;
            }
        }
    }

    &-lifetime {
        &-wrapper {
            display: flex;

            @include view-at(sp) {
                display: block;
            }
        }
        &-left {
            position: relative;
            width: 25%;

            @include view-at(sp) {
                width: 100%;
            }

            &-inner {
                @include view-at(pc) {
                    position: sticky;
                    top: 0;
                    left: 0;
                    margin-top: -100px;
                    padding-top: 100px;
                }
            }
        }
        &-right {
            width: 75%;

            @include view-at(sp) {
                width: 100%;
            }
        }

        &-title {
            color: $color-brown-dark;
            font-size: rem(28px);
            font-weight: $medium;
            line-height: 1.7;
            letter-spacing: 0.05em;
            writing-mode: vertical-rl;

            @include view-at(sp) {
                font-size: rem(24px);
                writing-mode: horizontal-tb;
                margin-bottom: 25px;
            }
        }
        &-subtitle {
            position: relative;
            color: $color-brown-dark;
            font-size: rem(18px);
            font-weight: $medium;
            line-height: 1.7;
            letter-spacing: 0.05em;
            margin-bottom: 15px;

            @include view-at(sp) {
                font-size: rem(16px);
            }

            .note {
                position: absolute;
                right: 0;
                bottom: 0;
                color: $color-text;
                font-size: 78%;
            }
        }

        &-home {
            &-type {
                display: flex;
                flex-wrap: wrap;
                margin: -8px;

                li {
                    width: calc(100% / 4);
                    padding: 8px;

                    @include view-at(sp) {
                        width: 50%;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: $color-white;
                        font-weight: $medium;
                        line-height: 1.625;
                        text-align: center;
                        padding: 24px 10px;

                        @include view-at(sp) {
                            padding: 14px 5px;
                        }

                        &.blue { background-color: #5DA0A9; }
                        &.yellow { background-color: #B68423; }
                        &.green { background-color: #75934F; }
                        &.pink { background-color: #D2818A; }
                    }
                }
            }

            &-flow {
                border-top: 1px solid $color-border;

                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 25px 0;
                    border-bottom: 1px solid $color-border;

                    @include view-at(sp) {
                        flex-wrap: wrap;
                        justify-content: center;
                    }

                    &:not(:last-child) {
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -10px;
                            width: 0;
                            height: 0;
                            margin: auto;
                            border-style: solid;
                            border-width: 16px 9px 0 9px;
                            border-color: $color-bright_yellow transparent transparent transparent;
                        }
                    }
                }

                &-number {
                    width: 12.2%;
                    padding-right: 40px;

                    @include view-at(sp) {
                        width: 25%;
                        padding-right: 0;
                    }

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 48px;
                        height: 48px;
                        color: $color-white;
                        font-size: rem(24px);
                        font-weight: $medium;
                        background-color: $color-bright_yellow;
                        border-radius: 50%;
                    }
                }
                &-icon {
                    width: 15.6%;
                    padding-right: 40px;

                    @include view-at(sp) {
                        width: 25%;
                        padding-right: 0px;
                    }
                }
                &-detail {
                    width: 72.2%;

                    @include view-at(sp) {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }

            &-example {
                display: flex;
                flex-wrap: wrap;
                margin: -13px -7px;

                @include view-at(sp) {
                    margin: -10px -5px;
                }

                li {
                    width: calc(100% / 3);
                    padding: 13px 7px;

                    @include view-at(sp) {
                        width: 50%;
                        padding: 10px 5px;
                    }
                }

                &-narrow {
                    letter-spacing: -0.04em;
                }
            }
        }

        &-salon {
            &-wrapper {
                display: flex;
                flex-direction: row-reverse;

                @include view-at(sp) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                &:not(:first-child) {
                    margin-top: 45px;

                    @include view-at(sp) {
                        margin-top: 40px;
                    }
                }
            }
            &-left {
                width: 21.4%;
                padding-right: 20px;

                @include view-at(sp) {
                    width: 50%;
                    padding-right: 0;
                    margin-top: 10px;
                }
            }
            &-right {
                width: 78.6%;

                @include view-at(sp) {
                    width: 100%;
                }
            }
    
            &-title {
                color: $color-white;
                font-size: rem(14px);
                line-height: 1.5;
                padding: 6px 5px 5px;
                background-color: $color-bright_yellow;
    
                .large {
                    font-size: 128.5%;
                    font-weight: $medium;
                    padding-left: 15px;
                }
            }
        }

        &-company {
            display: flex;
            flex-wrap: wrap;
            margin: -8px;

            li {
                width: 50%;
                padding: 8px;

                @include view-at(sp) {
                    width: 100%;
                }
            }

            &-inner {
                height: 100%;
                padding: 35px 30px 30px;
                background-color: $color-white;
                border-radius: 8px;
            }

            &-en {
                @include font-garamond;
                color: $color-bright_yellow;
                font-size: rem(38px);
                line-height: 1;
                text-align: center;
                margin-bottom: 30px;
            }
            &-title {
                color: $color-text-light;
                font-size: rem(18px);
                line-height: 1;
                text-align: center;
                margin-bottom: 25px;
            }
            &-detail {
                font-size: rem(14px);
                line-height: 2;
                margin-bottom: 40px;
            }
            &-logo {
                text-align: center;

                img {
                    max-height: 42px;
                }
            }
        }
    }

    &-member {
        &-title {
            @include view-at(pc) {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
        }

        &-point {
            display: flex;
            margin: 0 -14px;

            @include view-at(sp) {
                flex-wrap: wrap;
                margin: -10px;
            }

            li {
                width: calc(100% / 5);
                padding: 0 14px;

                @include view-at(sp) {
                    width: 100%;
                    display: flex;
                    padding: 10px;
                }

                > div {
                    @include view-at(sp) {
                        width: 30%;
                        padding-right: 15px;
                    }
                }
                > p {
                    @include view-at(sp) {
                        width: 70%;
                    }
                }
            }
        }

        &-contact {
            @include view-at(pc) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-title {
                line-height: 1.5;

                > .large {
                    font-size: 138%;
                }
            }
            &-tel {
                line-height: 1;

                a {
                    color: $color-brown;
                    font-size: rem(54px);

                    @include view-at(sp) {
                        font-size: rem(36px);
                    }

                    img {
                        height: 30px;
                        margin-right: 10px;
                        margin-bottom: 5px;
                        vertical-align: baseline;

                        @include view-at(sp) {
                            height: 24px;
                            margin-right: 5px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &-access {
        &-inner {
            @include view-at(pc) {
                max-width: 420px;
            }
        }

        &-tel {
            img {
                height: 12px;
                vertical-align: baseline;
            }
        }
    }

    &-map {
        width: 100%;
        height: 480px;

        @include view-at(sp) {
            height: 360px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    &-sp {
        @include view-at(sp) {
            &-full {
                margin: 0 -15px;
            }
        }
    }
}