/* --------------------------
Adobe Garamond Pro
-------------------------- */ 
@mixin font-garamond {
    font-family: 'adobe-garamond-pro', serif;
    font-weight: 400;
    font-style: normal;
}

/* --------------------------
游ゴシック
-------------------------- */ 
@mixin font-yugothic {
    font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', 'MS PGothic', sans-serif;
    font-weight: 400;
    font-style: normal;
}

