/* --------------------------
背景色
-------------------------- */

.u-bg-black {
    background-color: $color-black;
}

.u-bg-gray {
    background-color: $color-gray;
}

.u-bg-gray-snow {
    background-color: $color-gray-snow;
}

.u-bg-white {
    background-color: $color-white;
}

.u-bg-green {
    background-color: $color-green;
}

.u-bg-blue {
    background-color: $color-blue;
}

.u-bg-red {
    background-color: $color-red;
}

.u-bg-gold {
    background-color: $color-gold;
}

.u-bg-gold-snow {
    background-color: $color-gold-snow;
}

.u-bg-color {
    background-color: $color-bg;
}