html {
    font-size: 16px;
}

body {
    font-family: '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', 'MS P明朝', 'MS PMincho', serif;
    font-weight: 400;
    color: $color-text;
    font-size: rem(16px);
    text-align: justify;
    text-justify: inter-ideograph;
}

a {
    color: $color-text;
    text-decoration: none;
    transition: all .3s;

    &:hover {
        opacity: 0.6;
    }
}

p {
    margin-bottom: 1em;
    font-weight: $regular;
    line-height: 1.75;
    text-align: justify;
    text-justify: inter-ideograph;

    &:last-child {
        margin-bottom: 0;
    }

    @include view-at(sp) {
        font-size: 14px;
    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

.main {
    margin-top: 74px;
    
    @include view-at(sp) {
        margin-top: 54px;
    }
}