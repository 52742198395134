/* --------------------------
画像 + キャプション
-------------------------- */

.p-caption {
    position: absolute;
    bottom: 3px;
    color: $color-white;
    font-size: rem(10px);
    line-height: 1;
    text-shadow: 0 0 6px $color-black, 0 0 6px $color-black;

    &-top {
        top: 3px;
        bottom: auto;
    }

    &-left {
        left: 3px;
        text-align: left;

        &--sp {
            @include view-at(sp) {
                left: 3px;
                right: auto !important;
            }
        }
    }

    &-right {
        right: 3px;
        text-align: right;

        &--sp {
            @include view-at(sp) {
                left: auto !important;
                right: 3px;
            }
        }
    }

    &-black {
        color: $color-black;
        text-shadow: none;
    }

    &-white {
        text-shadow: none;
    }

    &-bg {
        right: 0;
        bottom: 0;
        color: $color-text;
        text-shadow: none;
        padding: 4px 5px;
        background-color: $color-white-dark;
    }
    
    &-wrapper {
        position: relative;
        min-height: 0%;
    }
}