/* --------------------------
リンク
-------------------------- */

.c-link {
    &--block {
        display: block;
        text-decoration: none;

        img {
            display: block;
        }
    }

    &--arrow {
        position: relative;
        padding-right: 20px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 8px;
            height: 8px;
            display: inline-block;
            border: 0px;
            border-top: solid 1px $color-blue;
            border-right: solid 1px $color-blue;
            transform: rotate(45deg);
            margin-top: -3px;
        }
    }

    &--underline {
        text-decoration: underline;
    }
}
