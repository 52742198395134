/* --------------------------
スクロールに合わせてフェードイン
-------------------------- */
.c-fadein {
  opacity: 0;
  transition: all 1s;

  &.js-active {
    opacity: 1;
  }

  &-wrapper {
    overflow: hidden;
  }

  &--top {
    transform: translateY(-20%);

    &.js-active {
      transform: translateY(0);
    }
  }

  &--bottom {
    transform: translateY(100px);

    @include view-at(sp) {
        transform: translateY(50px);
    }

    &.js-active {
      transform: translateY(0);
    }
  }

  &--left {
    transform: translateX(-100px);

    @include view-at(sp) {
        transform: translateX(-50px);
    }

    &.js-active {
      transform: translateY(0);
    }
  }

  &--right {
    transform: translateX(100px);

    @include view-at(sp) {
        transform: translateX(50px);
    }

    &.js-active {
      transform: translateY(0);
    }
  }

  &--delay {
    &-01 { transition-delay: 0.1s; }
    &-02 { transition-delay: 0.2s; }
    &-03 { transition-delay: 0.3s; }
    &-04 { transition-delay: 0.4s; }
    &-05 { transition-delay: 0.5s; }
    &-06 { transition-delay: 0.6s; }
    &-07 { transition-delay: 0.7s; }
    &-08 { transition-delay: 0.8s; }
    &-09 { transition-delay: 0.9s; }
    &-10 { transition-delay: 1.0s; }
    &-11 { transition-delay: 1.1s; }
    &-12 { transition-delay: 1.2s; }
    &-13 { transition-delay: 1.3s; }
    &-14 { transition-delay: 1.4s; }
    &-15 { transition-delay: 1.5s; }
    &-16 { transition-delay: 1.6s; }
    &-17 { transition-delay: 1.7s; }
    &-18 { transition-delay: 1.8s; }
    &-19 { transition-delay: 1.9s; }
    &-20 { transition-delay: 2.0s; }
    &-21 { transition-delay: 2.1s; }
    &-22 { transition-delay: 2.2s; }
    &-23 { transition-delay: 2.3s; }
    &-24 { transition-delay: 2.4s; }
    &-25 { transition-delay: 2.5s; }
    &-26 { transition-delay: 2.6s; }
    &-27 { transition-delay: 2.7s; }
    &-28 { transition-delay: 2.8s; }
    &-29 { transition-delay: 2.9s; }
    &-30 { transition-delay: 3.0s; }
  }
}