/* --------------------------
枠線
-------------------------- */

.u-border {
  border: 2px solid $color-gold;
  padding: 10px;

  &-bottom {
    border-bottom: 2px solid $color-gold;
    padding-bottom: 10px;
  }

  &-top {
    border-top: 2px solid $color-gold;
    padding-top: 10px;
  }

  &-right {
    border-right: 2px solid $color-gold;
    padding-right: 10px;
  }

  &-left {
    border-left: 2px solid $color-gold;
    padding-left: 10px;
  }

  &-thin {
    border-width: 1px;
  }

  &-bold {
    border-width: 4px;
  }

  &--white {
    border-color: $color-white;
  }

  &--gray {
    border-color: $color-gray;
  }

  &--black {
    border-color: $color-black;
  }
}
