/* --------------------------
テキスト装飾
-------------------------- */

.u-text-bright_yellow {
    color: $color-bright_yellow;
}
.u-text-brown {
    color: $color-brown;
}

.u-text-red {
  color: $color-red;
}

.u-text-blue-dark {
  color: $color-blue-dark;
}

.u-text-gold {
  color: $color-gold;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-note {
  font-size: rem(12px);
  line-height: 1.6;
  letter-spacing: 0;
  text-align: justify;
  text-justify: inter-ideograph;
}

.u-text-weight {
    &-bold {
        font-weight: $bold;
    }
    &-medium {
        font-weight: $medium;
    }
    &-normal {
        font-weight: $normal;
    }
}

.u-text-lh {
    &-10 { line-height: 1; }
    &-15 { line-height: 1.5; }
    &-20 { line-height: 2; }
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-yellow 70%);
}

.u-text-underline {
  text-decoration: underline;
}

.u-text-x-large {
  font-size: rem(30px);

  @include view-at(sp) {
    font-size: rem(24px);
  }
}

.u-text-large {
  font-size: rem(24px);

  @include view-at(sp) {
    font-size: rem(20px);
  }
}

.u-text-bit-large {
  font-size: rem(20px);

  @include view-at(sp) {
    font-size: rem(18px);
  }
}

.u-text-medium {
  font-size: rem(18px);

  @include view-at(sp) {
    font-size: rem(16px);
  }
}

.u-text-default {
  font-size: rem(16px);

  @include view-at(sp) {
    font-size: rem(14px);
  }
}

.u-text-small {
  font-size: rem(14px);

  @include view-at(sp) {
    font-size: rem(12px);
  }
}

.u-text-x-small {
  font-size: rem(12px);

  @include view-at(sp) {
    font-size: rem(10px);
  }
}
